import React, { useEffect, useState, useRef } from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.css";
import DomToImage from "dom-to-image";
import axios from "axios";
import * as _ from "lodash";

function HappyHours() {
  const apiURL = "https://api.zilasikar.in/Voter";

  const [voters, setVoters] = useState(null);
  const [ranks, setRanks] = useState(null);
  const [name, setName] = useState("");
  const [mobileNo, setMobileNo] = useState("");
  const [divisionNo, setDivisionNo] = useState("");
  const [epicNo, setEpicNo] = useState("");
  const [isSaved, setIsSaved] = useState(false);
  const elementRef = useRef<any>(null);
  const [selectedConstituency, setSelectedConstituency] = useState<any>({
    value: "",
    label: "--Select--",
  });

  const constituencyOptions = [
    { value: "", label: "--Select--" },
    { value: "Khandela", label: "खण्डेला" },
    { value: "Dantaramgarh", label: "दांतारामगढ़" },
    { value: "Dhod", label: "धोद" },
    { value: "NeemKaThana", label: "नीमकाथाना" },
    { value: "Fatehpur", label: "फतेहपुर" },
    { value: "Laxmangarh", label: "लक्ष्मणगढ़" },
    { value: "Sikar", label: "सीकर" },
    { value: "ShreeMadhopur", label: "श्रीमाधोपुर" },
  ];

  const handleSelectChange = (e: any) => {
    console.log(
      constituencyOptions.find((option) => option.value == e.target.value)
    );
    setSelectedConstituency(
      constituencyOptions.find((option) => option.value == e.target.value)
    );
  };

  async function loadVoter() {
    try {
      const response = await axios.get(apiURL + "/HappyHours");
      const res: any[] = await response.data;

      const sortedArray = _.orderBy(res, ["count"], ["desc"]);

      let voters: any = {};
      let tmpRanks: any = {};
      let totalCount = 0;
      constituencyOptions.forEach((option) => {
        let found = false;
        let rank = 0;
        sortedArray.forEach((element) => {
          rank = rank + 1;
          if (
            element.constituency != "" &&
            element.constituency == option.value
          ) {
            found = true;
            voters[option.value] = element.count;
            tmpRanks[option.value] = rank;
            totalCount = totalCount + element.count;
          }
        });

        if (!found) voters[option.value] = 0;
      });
      voters["total"] = totalCount;

      setVoters(voters);
      setRanks(tmpRanks);
    } catch (error) {
      console.log("Error Loading data:", error);
    }
  }

  useEffect(() => {
    loadVoter();
  }, []);

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    try {
      debugger;
      const response = await axios.post(apiURL + "/SaveHappyHourVoter", {
        id: 0,
        name: name,
        mobileNo: mobileNo,
        constituency: selectedConstituency.value,
        entryDate: "2023-09-04T09:07:29.441Z",
        divisionNo: divisionNo,
        epicNo: epicNo,
      });
      loadVoter();
      setIsSaved(true);
    } catch (err: any) {
      setIsSaved(false);
    }
  };

  const downloadCertificate = () => {
    if (elementRef.current) {
      elementRef.current.style.display = "flex";
      DomToImage.toJpeg(elementRef.current, { quality: 1 })
        .then((dataUrl) => {
          DomToImage.toJpeg(elementRef.current, { quality: 1 })
            .then((dataUrl) => {
              const link = document.createElement("a");
              link.download = `${name}.jpeg`;
              link.href = dataUrl;
              link.click();
              if (elementRef.current) {
                elementRef.current.style.display = "none";
              }
              setIsSaved(false);
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  useEffect(() => {
    if (isSaved) downloadCertificate();
  }, [isSaved]);

  return (
    <>
      <header>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="img-thambh">
                <img src="./ashok-stambh.png" alt="ashok st " />
              </div>
              <div className="title">राजस्थान सरकार</div>
              <div className="heading">
                जिला निर्वाचन अधिकारी एवं जिला कलक्टर,सीकर
              </div>
              <div className="sub-heading">
                सुव्‍यवस्थित मतदाता शिक्षा एवं निर्वाचक सहभागिता. ''मजबूत
                लोकतंत्र-सबकी भागीदारी''.
              </div>
            </div>
          </div>
        </div>
      </header>
      {/* <section className="nav-inverse" /> */}

      <section className="sikar-logo">
        <img src="./MascotUpdated.png" alt="Mascot Sikar" />
      </section>

      <section className="strong">
        <div className="container">
          <div className="col-sm-12 text">
            <div className="heading">
              मजबूत लोकतंत्र के लिए <span>जन जागरूकता अभियान</span>
            </div>
            <section
              className="sikar-logo"
              style={{ paddingTop: 10, paddingBottom: 10 }}
            >
              <img src="./VoteSymbol.jpg" alt="Mascot Sikar" />
            </section>
            <h2>मेरा दायित्व</h2>
            <p>
              मैं प्रमाणित करता/करती हूँ कि मैंने लोकसभा आम चुनाव, 2024 में अपने
              मताधिकार का प्रयोग किया है और भविष्य में भी इस सम्बन्ध में अपने
              दायित्व का जिम्मेदारी पूर्वक निर्वहन करूँगा/करुँगी। साथ ही मैं
              परिवारजनों, पड़ोसियों, मित्रजन एवम् रिश्तेदारों को प्रत्येक चुनाव
              में मतदान करने के लिए प्रेरित करूँगा/करूँगी।
            </p>
            <section
              className="EvmLogo"
              style={{ paddingTop: 10, paddingBottom: 10, textAlign: "center" }}
            >
              <img src="./EVM Machine.png" alt="EVM Machine" />
            </section>
          </div>
        </div>
      </section>
      <section className="form">
        <div className="container">
          <div className="row">
            <div className="col-sm-8">
              <form className="section-form" onSubmit={handleSubmit}>
                <fieldset disabled>
                  <legend className="bg">Please input in English</legend>
                  <div className="mb-3">
                    <label htmlFor="disabledTextInput" className="form-label">
                      जिला{" "}
                    </label>
                    <input
                      type="text"
                      id="disabledTextInput"
                      className="form-control"
                      placeholder="सीकर"
                      disabled
                    />
                  </div>
                </fieldset>
                <div className="mb-3">
                  <label htmlFor="exampleInputPassword1" className="form-label">
                    विधानसभा क्षेत्र
                  </label>

                  <select
                    className="form-select"
                    value={selectedConstituency.value}
                    onChange={handleSelectChange}
                    required
                  >
                    {constituencyOptions.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="mb-3">
                  <label htmlFor="exampleInputPassword1" className="form-label">
                    नाम{" "}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="exampleInputPassword1"
                    value={name || ""}
                    required
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="exampleInputAreaNo" className="form-label">
                    भाग संख्या{" "}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="exampleInputAreaNo"
                    value={divisionNo || ""}
                    required
                    onChange={(e) => setDivisionNo(e.target.value)}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="exampleInputEpicNo" className="form-label">
                    EPIC क्रमांक{" "}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="exampleInputEpicNo"
                    value={epicNo || ""}
                    required
                    onChange={(e) => setEpicNo(e.target.value)}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="exampleInputPassword1" className="form-label">
                    मोबाइल नंबर [10 अंक]{" "}
                  </label>
                  <input
                    className="form-control"
                    id="exampleInputPassword1"
                    type="tel"
                    pattern="[0-9]{10}"
                    maxLength={10}
                    value={mobileNo || ""}
                    onChange={(e) => setMobileNo(e.target.value)}
                    required
                  />
                </div>
                <div className="mb-3 form-check">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="exampleCheck1"
                    required
                  />
                  <label className="form-check-label" htmlFor="exampleCheck1">
                    मैंने लोकसभा आम चुनाव, 2024 में अपने मताधिकार का
                    प्रयोग किया है।
                  </label>
                </div>
                <button type="submit" className="btn btn-primary">
                  Submit
                </button>
              </form>
            </div>

            <div className="col-sm-4">
              <div className="candidate">
                <div className="row">
                  <div className="col-sm-12 nagrik">
                    कुल नागरिक जिनके द्वारा संकल्प पत्र भरा गया है।
                    <br />
                    <span>{voters && voters["total"]}</span>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-6 total">
                    खण्डेला ({ranks && ranks["Khandela"]}) <br />
                    <span>{voters && voters["Khandela"]}</span>
                  </div>
                  <div className="col-sm-6 total">
                    दांतारामगढ़ ({ranks && ranks["Dantaramgarh"]}) <br />
                    <span>{voters && voters["Dantaramgarh"]}</span>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-6 total">
                    धोद ({ranks && ranks["Dhod"]}) <br />{" "}
                    <span>{voters && voters["Dhod"]}</span>
                  </div>
                  <div className="col-sm-6 total">
                    नीमकाथाना ({ranks && ranks["NeemKaThana"]}) <br />
                    <span>{voters && voters["NeemKaThana"]}</span>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-6 total">
                    फतेहपुर ({ranks && ranks["Fatehpur"]}) <br />
                    <span>{voters && voters["Fatehpur"]}</span>
                  </div>
                  <div className="col-sm-6 total">
                    लक्ष्मणगढ़ ({ranks && ranks["Laxmangarh"]}) <br />{" "}
                    <span>{voters && voters["Laxmangarh"]}</span>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-6 total">
                    सीकर ({ranks && ranks["Sikar"]}) <br />{" "}
                    <span>{voters && voters["Sikar"]}</span>
                  </div>
                  <div className="col-sm-6 total">
                    श्रीमाधोपुर ({ranks && ranks["ShreeMadhopur"]}) <br />{" "}
                    <span>{voters && voters["ShreeMadhopur"]}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <footer>कार्यालय जिला कलक्टर, सीकर</footer>

      {
        <div
          ref={elementRef}
          style={{
            display: "none",
            alignItems: "center",
            justifyContent: "center",
            height: "904px",
            width: "1280px",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "904px",
              width: "1280px",
            }}
          >
            <div
              style={{
                //backgroundImage: "url(./certificateupdated.jpg)",
                backgroundImage: "url(./CertificateUpdated-loksabha_Blank.jpg)",
                height: "100%",
                width: "100%",
                backgroundRepeat: "no-repeat",
              }}
            >
              <div
                style={{
                  color: "#600000",
                  marginTop: 350,
                  paddingInline: "119px",
                  textAlign: "center",
                  lineHeight: "1px",
                }}
              >
                <p
                  style={{ fontSize: 29, textAlign: "left", paddingBottom: 0 }}
                >
                  श्री/श्रीमती/सुश्री
                  <span
                    style={{
                      textAlign: "center",
                      display: "inline-block",
                      borderBottom: "5px dotted",
                      padding: 13,
                      width: 840,
                    }}
                  >
                    {name}
                  </span>
                  {/* मोबाइल नम्बर */}
                </p>
                <p style={{ fontSize: 29, textAlign: "left", paddingTop: 5 }}>
                  मतदान केंद्र भाग संख्या
                  <span
                    style={{
                      textAlign: "center",
                      display: "inline-block",
                      width: 310,
                      borderBottom: "5px dotted",
                      padding: 13,
                    }}
                  >
                    {divisionNo}
                  </span>
                  , EPIC क्रमांक
                  <span
                    style={{
                      textAlign: "center",
                      display: "inline-block",
                      width: 310,
                      borderBottom: "5px dotted",
                      padding: 13,
                    }}
                  >
                    {epicNo}
                  </span>
                </p>
                <p style={{ fontSize: 29, textAlign: "left", paddingTop: 10 }}>
                  विधानसभा क्षेत्र -
                  <span
                    style={{
                      textAlign: "center",
                      display: "inline-block",
                      width: 375,
                      borderBottom: "5px dotted",
                      padding: 13,
                    }}
                  >
                    {selectedConstituency.label}
                  </span>
                  , लोकसभा क्षेत्र -{" "}
                  {selectedConstituency.value === "Fatehpur"
                    ? "झुंझुनू"
                    : "सीकर"}{" "}
                  को <span style={{ fontWeight: "bold" }}>लोकसभा आम</span>
                </p>
                <p style={{ fontSize: 29, textAlign: "left", paddingTop: 20 }}>
                  <span style={{ fontWeight: "bold" }}>
                    चुनाव 2024 में Happy Hours (07:00 AM to 10:00 AM) में मतदान
                    करने पर{" "}
                  </span>{" "}
                  बधाई ।
                </p>

                <p style={{ fontSize: 29, paddingTop: 50 }}>
                  एतद् द्वारा आपको जागरुक मतदाता प्रमाण पत्र जारी किया जाता है ।
                </p>
              </div>
            </div>
          </div>
        </div>
      }
    </>
  );
}

export default HappyHours;
