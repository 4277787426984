import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import HappyHours from "./HappyHours";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
const currentDate = new Date();
const targetDate = new Date("2024-04-19T07:00:00");
const endDate = new Date("2024-04-19T10:00:00");

console.log("currentDate - ", currentDate);

const urlParams = new URLSearchParams(window.location.search);
const param = urlParams.get("param");

root.render(
  <React.StrictMode>
    {param == "hh" || (currentDate >= targetDate && currentDate < endDate) ? (
      <HappyHours />
    ) : (
      <App />
    )}
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
