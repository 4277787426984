import React, { useEffect, useState, useRef } from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.css";
import DomToImage from "dom-to-image";
import axios from "axios";
import * as _ from "lodash";

function App() {
  const apiURL = "https://api2.zilasikar.in/Voter";

  const [voters, setVoters] = useState(null);
  const [ranks, setRanks] = useState(null);
  const [name, setName] = useState("");
  const [mobileNo, setMobileNo] = useState("");
  const [isSaved, setIsSaved] = useState(false);
  const elementRef = useRef<any>(null);
  const [selectedConstituency, setSelectedConstituency] = useState<any>({
    value: "",
    label: "--Select--",
  });

  const constituencyOptions = [
    { value: "", label: "--Select--" },
    { value: "Khandela", label: "खण्डेला" },
    { value: "Dantaramgarh", label: "दांतारामगढ़" },
    { value: "Dhod", label: "धोद" },
    { value: "NeemKaThana", label: "नीमकाथाना" },
    { value: "Fatehpur", label: "फतेहपुर" },
    { value: "Laxmangarh", label: "लक्ष्मणगढ़" },
    { value: "Sikar", label: "सीकर" },
    { value: "ShreeMadhopur", label: "श्रीमाधोपुर" },
  ];

  const handleSelectChange = (e: any) => {
    console.log(
      constituencyOptions.find((option) => option.value == e.target.value)
    );
    setSelectedConstituency(
      constituencyOptions.find((option) => option.value == e.target.value)
    );
  };

  async function loadVoter() {
    try {
      const response = await axios.get(apiURL);
      const res: any[] = await response.data;

      const sortedArray = _.orderBy(res, ["count"], ["desc"]);

      let voters: any = {};
      let tmpRanks: any = {};
      let totalCount = 0;
      constituencyOptions.forEach((option) => {
        let found = false;
        let rank = 0;
        sortedArray.forEach((element) => {
          rank = rank + 1;
          if (
            element.constituency != "" &&
            element.constituency == option.value
          ) {
            found = true;
            voters[option.value] = element.count;
            tmpRanks[option.value] = rank;
            totalCount = totalCount + element.count;
          }
        });

        if (!found) voters[option.value] = 0;
      });
      voters["total"] = totalCount;

      setVoters(voters);
      setRanks(tmpRanks);
    } catch (error) {
      console.log("Error Loading data:", error);
    }
  }

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     loadVoter();
  //   }, 300000000);

  //   //Clearing the interval
  //   return () => clearInterval(interval);
  // }, [voters]);

  useEffect(() => {
    loadVoter();
  }, []);

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    try {
      debugger;
      const response = await axios.post(apiURL, {
        id: 0,
        name: name,
        mobileNo: mobileNo,
        constituency: selectedConstituency.value,
        entryDate: "2023-09-04T09:07:29.441Z",
      });
      loadVoter();
      setIsSaved(true);
    } catch (err: any) {
      setIsSaved(false);
    }
  };

  const downloadCertificate = () => {
    if (elementRef.current) {
      elementRef.current.style.display = "flex";
      DomToImage.toJpeg(elementRef.current, { quality: 1 })
        .then((dataUrl) => {
          DomToImage.toJpeg(elementRef.current, { quality: 1 })
            .then((dataUrl) => {
              const link = document.createElement("a");
              link.download = `${name}.jpeg`;
              link.href = dataUrl;
              link.click();
              if (elementRef.current) {
                elementRef.current.style.display = "none";
              }
              setIsSaved(false);
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  useEffect(() => {
    if (isSaved) downloadCertificate();
  }, [isSaved]);

  return (
    <>
      <header>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="img-thambh">
                <img src="./ashok-stambh.png" alt="ashok st " />
              </div>
              <div className="title">राजस्थान सरकार</div>
              <div className="heading">
                जिला निर्वाचन अधिकारी एवं जिला कलक्टर,सीकर
              </div>
              <div className="sub-heading">
                सुव्‍यवस्थित मतदाता शिक्षा एवं निर्वाचक सहभागिता. ''मजबूत
                लोकतंत्र-सबकी भागीदारी''.
              </div>
            </div>
          </div>
        </div>
      </header>
      {/* <section className="nav-inverse" /> */}

      <section className="sikar-logo">
        <img src="./MascotUpdated.png" alt="Mascot Sikar" />
      </section>

      <section className="strong">
        <div className="container">
          <div className="col-sm-12 text">
            <div className="heading">
              मजबूत लोकतंत्र के लिए <span>जन जागरूकता अभियान</span>
            </div>
            <h2>मेरा संकल्प</h2>
            <p>
              मैं भारत का नागरिक, लोकतंत्र में अपनी पूर्ण आस्था रखते हुए यह शपथ
              लेता / लेती हूं कि मैं, अपने देश की लोकतांत्रिक परम्पराओं की
              मर्यादा को बनाए रखूंगा / रखूंगी तथा स्वतंत्र, निष्पक्ष एवं
              शांतिपूर्ण निर्वाचन की गरिमा को अक्षुण्ण रखते हुए, निर्भीक होकर,
              धर्म, वर्ग, जाति, समुदाय, भाषा अथवा अन्य किसी भी प्रलोभन से
              प्रभावित हुए बिना सभी निर्वाचनों में अपने मताधिकार का प्रयोग
              करूंगा / करूगीं।
              <br />
              <br />
              मैं, सीकर जिले में मजबूत लोकतंत्र के लिए चलाये जा रहे{" "}
              <b>'SVEEP' Program </b>का समर्थन करता/करती हूँ और मजबूत लोकतंत्र
              की परिकल्पना को साकार करने में मेरी क्षमता के अनुसार सहयोग का
              संकल्प लेता/लेती हूँ ।
            </p>
          </div>
        </div>
      </section>
      <section className="form">
        <div className="container">
          <div className="row">
            <div className="col-sm-8">
              <form className="section-form" onSubmit={handleSubmit}>
                <fieldset disabled>
                  <legend className="bg">Please input in English</legend>
                  <div className="mb-3">
                    <label htmlFor="disabledTextInput" className="form-label">
                      जिला{" "}
                    </label>
                    <input
                      type="text"
                      id="disabledTextInput"
                      className="form-control"
                      placeholder="सीकर"
                      disabled
                    />
                  </div>
                </fieldset>
                <div className="mb-3">
                  <label htmlFor="exampleInputPassword1" className="form-label">
                    विधानसभा क्षेत्र
                  </label>

                  <select
                    className="form-select"
                    value={selectedConstituency.value}
                    onChange={handleSelectChange}
                    required
                  >
                    {constituencyOptions.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="mb-3">
                  <label htmlFor="exampleInputPassword1" className="form-label">
                    नाम{" "}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="exampleInputPassword1"
                    value={name || ""}
                    required
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="exampleInputPassword1" className="form-label">
                    मोबाइल नंबर [10 अंक]{" "}
                  </label>
                  <input
                    className="form-control"
                    id="exampleInputPassword1"
                    type="tel"
                    pattern="[0-9]{10}"
                    maxLength={10}
                    value={mobileNo || ""}
                    onChange={(e) => setMobileNo(e.target.value)}
                    required
                  />
                </div>
                <div className="mb-3 form-check">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="exampleCheck1"
                    required
                  />
                  <label className="form-check-label" htmlFor="exampleCheck1">
                    मैं मताधिकार का संकल्प लेता / लेती हूं।
                  </label>
                </div>
                <button type="submit" className="btn btn-primary">
                  Submit
                </button>
              </form>
            </div>

            <div className="col-sm-4">
              <div className="candidate">
                <div className="row">
                  <div className="col-sm-12 nagrik">
                    कुल नागरिक जिनके द्वारा संकल्प पत्र भरा गया है।
                    <br />
                    <span>{voters && voters["total"]}</span>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-6 total">
                    खण्डेला ({ranks && ranks["Khandela"]}) <br />
                    <span>{voters && voters["Khandela"]}</span>
                  </div>
                  <div className="col-sm-6 total">
                    दांतारामगढ़ ({ranks && ranks["Dantaramgarh"]}) <br />
                    <span>{voters && voters["Dantaramgarh"]}</span>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-6 total">
                    धोद ({ranks && ranks["Dhod"]}) <br />{" "}
                    <span>{voters && voters["Dhod"]}</span>
                  </div>
                  <div className="col-sm-6 total">
                    नीमकाथाना ({ranks && ranks["NeemKaThana"]}) <br />
                    <span>{voters && voters["NeemKaThana"]}</span>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-6 total">
                    फतेहपुर ({ranks && ranks["Fatehpur"]}) <br />
                    <span>{voters && voters["Fatehpur"]}</span>
                  </div>
                  <div className="col-sm-6 total">
                    लक्ष्मणगढ़ ({ranks && ranks["Laxmangarh"]}) <br />{" "}
                    <span>{voters && voters["Laxmangarh"]}</span>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-6 total">
                    सीकर ({ranks && ranks["Sikar"]}) <br />{" "}
                    <span>{voters && voters["Sikar"]}</span>
                  </div>
                  <div className="col-sm-6 total">
                    श्रीमाधोपुर ({ranks && ranks["ShreeMadhopur"]}) <br />{" "}
                    <span>{voters && voters["ShreeMadhopur"]}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <footer>कार्यालय जिला कलक्टर, सीकर</footer>

      {
        <div
          ref={elementRef}
          style={{
            display: "none",
            alignItems: "center",
            justifyContent: "center",
            height: "904px",
            width: "1280px",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "904px",
              width: "1280px",
            }}
          >
            <div
              style={{
                //backgroundImage: "url(./certificateupdated.jpg)",
                backgroundImage: "url(./CertificateUpdated-loksabha_Blank.jpg)",
                height: "100%",
                width: "100%",
                backgroundRepeat: "no-repeat",
              }}
            >
              <div
                style={{
                  color: "#600000",
                  marginTop: 350,
                  paddingInline: "150px",
                  textAlign: "center",
                  lineHeight: "30px",
                }}
              >
                <p
                  style={{ fontSize: 30, textAlign: "left", paddingBottom: 20 }}
                >
                  श्री/श्रीमती/सुश्री
                  <span
                    style={{
                      textAlign: "center",
                      display: "inline-block",
                      borderBottom: "5px dotted",
                      width: 750,
                    }}
                  >
                    {name}
                  </span>
                  <br></br>
                  {/* मोबाइल नम्बर */}
                </p>
                <p
                  style={{
                    fontSize: 30,
                    textAlign: "left",
                    paddingBlock: 15,
                  }}
                >
                  लोकसभा क्षेत्र -{" "}
                  {selectedConstituency.value === "Fatehpur"
                    ? "झुंझुनू"
                    : "सीकर"}{" "}
                  {/* विधानसभा
                  <span
                    style={{
                      textAlign: "center",
                      display: "inline-block",
                      width: 400,
                      borderBottom: "5px dotted",
                    }}
                  >
                    {selectedConstituency.label}
                  </span>*/}
                  को मतदान का संकल्प लेने पर बधाई ।
                </p>
                <p style={{ fontSize: 30, paddingBlock: 15 }}>
                  एतद् द्वारा आपको जागरुक मतदाता प्रमाण पत्र जारी किया जाता है ।
                </p>
              </div>
            </div>
          </div>
        </div>
      }
    </>
  );
}

export default App;
